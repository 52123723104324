<template>
  <div class='subject-info'>
    <el-form v-model="subjectInfoFrom" :rules="subjectInfoRules" :inline="true" label-width="120px">
      <el-row>
        <!-- <el-col :span="6"> -->
        <el-form-item label="分销商主体类型">
          <el-input v-model="subjectInfoFrom.catogry"></el-input>
        </el-form-item>
        <!-- </el-col> -->
        <!-- <el-col :span="6"> -->
        <el-form-item label="分销商主体类型">
          <el-input v-model="subjectInfoFrom.catogry"></el-input>
        </el-form-item>
        <!-- </el-col> -->
        <!-- <el-col :span="6"> -->
        <el-form-item label="分销商主体类型">
          <el-input v-model="subjectInfoFrom.catogry"></el-input>
        </el-form-item>
        <!-- </el-col> -->
      </el-row>
      <el-row>
        <el-form-item label="营业执照">

        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>

export default {
  name: 'SubjectInfo',
  components: {},
  data () {
    return {
      subjectInfoFrom: {},
      subjectInfoRules: []
    }
  },
  created () { },
  methods: {},
}
</script>
<style lang='less' scoped>
</style>