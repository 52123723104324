<template>
  <div class='log'>
    log
  </div>
</template>

<script>

export default {
  name: 'Log',
  components: {},
  data () {
    return {}
  },
  created () { },
  methods: {},
}
</script>
<style lang='less' scoped>
</style>