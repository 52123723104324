<template>
    <!-- 分销商日报 -->
    <div class="daily page">
        <!-- <gb-table :table="table" :isShowPagination="true" @pageChange="pageChange" @sizeChange="sizeChange" /> -->
        <el-form
            class="page-search"
            :inline="true"
            label-width="100px"
            @submit.native.prevent
        >
            <div class="left">
                <el-form-item label="">
                    <el-input
                        v-model="searchForm.productTitle"
                        placeholder="产品名称"
                        clearable
                        @keyup.enter.native="querydata"
                        @clear="querydata"
                    ></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-select
                        v-model="searchForm.selectIdParams"
                        filterable
                        multiple
                        collapse-tags
                        style="margin-left: 20px;"
                        clearable
                        value-key="selectId"
                        placeholder="分销商"
                    >
                        <el-option
                            v-for="item in distributors"
                            :key="item.selectId"
                            :label="item.showName"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="">
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :clearable="false"
                        @change="dateChange"
                    >
                    </el-date-picker>
                </el-form-item>
            </div>
            <div class="right">
                <div class="search-btn">
                    <div class="custom-btn">
                        <el-button
                            type="primary"
                            @click="querydata"
                        >
                            查询
                        </el-button>
                        <el-button @click="reset">重置</el-button>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <gb-table
                v-show="show"
                :table="table"
                :show-summary="true"
                :is-show-pagination="false"
                :summary-method="table.data.length > 0 ? getSummaries : null"
                @pageChange="pageChange"
                @sizeChange="sizeChange"
            />
        </div>
    </div>
</template>

<script>
import { moneyFilter } from '@/utils/index'
import { getDistributors } from 'services/distribution'
export default {
    name: 'DistributionList',
    components: {},
    data () {
        return {
            table: {
                data: [],
                // firstColumn: { type: 'index' },
                // 标题
                column: []
            },
            // 查询条件数据
            searchForm: {
                selectIdParams: [],
                spuIds: []
            },
            distributors: [], // 分销商列表
            dateRange: [],
            totalData: [],
            show: false
        }
    },
    created () {
        this.searchForm.selectFrom = this.$moment()
            .subtract(7, 'day')
            .format('YYYY-MM-DD')
        this.searchForm.selectTo = this.$moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        this.dateRange = [
            new Date(this.searchForm.selectFrom),
            new Date(this.searchForm.selectTo)
        ]
        this.getDistributorList()
        this.initData()
        this.getOptionData()
    },
    methods: {
        initData (search) {
            let days = this.$moment(this.dateRange[1]).diff(
                this.$moment(this.dateRange[0]),
                'days'
            )
            if (days > 30) {
                this.$message.warning('最多查询30天数据！')
                return
            }
            this.show = false
            this.table.column = [
                {
                    prop: 'productTitle',
                    label: '产品名称',
                    minWidth: '130',
                    showTip: true,
                    align: 'left',
                    tip: '销量/销售额',
                    fixed: true
                },
                {
                    // prop: 'total', label: `1月合计`, minWidth: '100',
                    prop: 'total',
                    label: '合计',
                    minWidth: '150',
                    formatter: row => {
                        return `${row.totalData.itemCountSum}/${'￥' +
                            moneyFilter(row.totalData.itemAmountSum)}`
                    }
                }
            ]
            this.$axios
                .fetch(
                    'distributionServer',
                    '/distribution/distributor-spu-report',
                    this.searchForm,
                    'json'
                )
                .then(res => {
                    const { selectFrom, selectTo } = this.searchForm
                    let count = this.$moment(selectTo).diff(
                        this.$moment(selectFrom),
                        'day'
                    )
                    console.log(count)
                    for (let index = 0; index <= count; index++) {
                        let obj = {
                            minWidth: '150'
                        }
                        obj.label = this.$moment(selectFrom)
                            .add(index, 'day')
                            .format('YYYY-MM-DD')
                        obj.formatter = data => {
                            let count =
                                data.distributionSpuReportsData[index].itemCount
                            let account =
                                data.distributionSpuReportsData[index]
                                    .itemAmount
                            return `${count}/${'￥' + moneyFilter(account)}`
                        }
                        this.table.column.push(obj)
                    }
                    if (res.data && res.data.length > 0) {
                        this.totalData = this.table.data = res.data
                        if (search) {
                            if (
                                this.searchForm.productTitle &&
                                this.searchForm.productTitle.length > 0
                            ) {
                                this.table.data = this.totalData.filter(
                                    item => {
                                        return item.productTitle.includes(
                                            this.searchForm.productTitle.replace(
                                                /\s+/g,
                                                ''
                                            )
                                        )
                                    }
                                )
                            } else {
                                this.table.data = this.totalData
                            }
                        }
                    } else {
                        this.table.data = []
                    }
                    this.show = true
                })
        },
        async getDistributorList () {
            try {
                const { data = [] } = await getDistributors()
                this.distributors = data
            } catch (error) {
                console.log(error)
            }
        },
        dateChange (val) {
            if (val && val.length > 0) {
                this.searchForm.selectFrom = val[0]
                this.searchForm.selectTo = val[1]
            }
        },
        // 从字典获取<分销商分类>
        getOptionData () {
            this.$axios
                .fetch('commonServer', '/dict/getItemViewsByParentNo', {
                    parentNos: '分销商渠道'
                })
                .then(res => {})
        },
        pageChange (val) {
            this.table.currentPage = val
            this.initData()
        },
        sizeChange (val) {
            this.table.currentPage = 1
            this.table.pageSize = val
            this.initData()
        },
        /* 查询第几页数据 */
        handleCurrentChange (val) {
            this.searchForm.pageIndex = val
            this.initData(this.searchForm)
        },
        handlesSizeChange (val) {
            this.currentPage = 1
            this.searchForm.pageIndex = 1
            this.searchForm.pageSize = val
            this.initData(this.searchForm)
        },
        querydata () {
            this.initData(true)
        },
        contentChange () {
            this.conceal = !this.conceal
        },
        reset () {
            let selectFrom = this.$moment()
                .subtract(7, 'day')
                .format('YYYY-MM-DD')
            let selectTo = this.$moment()
                .subtract(1, 'days')
                .format('YYYY-MM-DD')
            this.searchForm.selectFrom = selectFrom
            this.searchForm.selectTo = selectTo
            this.dateRange = [
                new Date(this.searchForm.selectFrom),
                new Date(this.searchForm.selectTo)
            ]
            this.searchForm.productTitle = ''
            this.searchForm.selectIdParams = []
            this.initData(true)
        },
        // 合计
        getSummaries (param) {
            console.log(param)
            const { columns, data } = param
            if (data && data.length > 0) {
                const sums = []
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = ''
                        return
                    }
                    if (index === 1) {
                        sums[index] = '合计'
                    } else if (index === 2) {
                        const valueToal = data.map(item =>
                            Number(item.totalData.itemAmountSum)
                        )
                        const salesTotal = data.map(item =>
                            Number(item.totalData.itemCountSum)
                        )
                        let total1 = valueToal.reduce((prev, curr) => {
                            const value = Number(curr)
                            if (!isNaN(value)) {
                                return prev + curr
                            } else {
                                return prev
                            }
                        }, 0)
                        let total2 = salesTotal.reduce((prev, curr) => {
                            const value = Number(curr)
                            if (!isNaN(value)) {
                                return prev + curr
                            } else {
                                return prev
                            }
                        }, 0)
                        sums[index] = `${total2}/￥${moneyFilter(total1)}`
                    } else {
                        const values = data.map(item =>
                            Number(
                                item.distributionSpuReportsData[index - 3]
                                    .itemAmount
                            )
                        )
                        const sales = data.map(item =>
                            Number(
                                item.distributionSpuReportsData[index - 3]
                                    .itemCount
                            )
                        )
                        if (!values.every(value => isNaN(value))) {
                            let total1 = values.reduce((prev, curr) => {
                                const value = Number(curr)
                                if (!isNaN(value)) {
                                    return prev + curr
                                } else {
                                    return prev
                                }
                            }, 0)
                            let total2 = sales.reduce((prev, curr) => {
                                const value = Number(curr)
                                if (!isNaN(value)) {
                                    return prev + curr
                                } else {
                                    return prev
                                }
                            }, 0)
                            sums[index] = `${total2}/￥${moneyFilter(total1)}`
                        } else {
                            sums[index] = 'N/A'
                        }
                    }
                })
                return sums
            }
        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-select__tags {
    .el-tag {
        display: flex;
        align-items: center;

        > span {
            display: inline-block;
            width: 100%;
            max-width: 0.8rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .el-tag__close {
            top: 1px;
            display: inline-block;
            width: 12px;
            height: 12px;
            line-height: 12px;
        }
    }
}
</style>
