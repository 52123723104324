<template>
    <!-- 分销list -->
    <div class="distribution-list page">
        <!-- <gb-table :table="table" :isShowPagination="true" @pageChange="pageChange" @sizeChange="sizeChange" /> -->
        <el-form class="page-search" :inline="true" label-width="100px">
            <div class="left">
                <div class="show">
                    <el-form-item label="">
                        <el-input
                            v-model="searchForm.distributorName"
                            clearable
                            placeholder="分销商名称"
                            @keyup.enter.native="querydata()"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <el-input
                            v-model="searchForm.partnerName"
                            clearable
                            placeholder="主体名称"
                            @keyup.enter.native="querydata()"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <department
                            ref="dept"
                            v-model="searchForm.groupId"
                            api-name="getOwnDepts"
                            :is-cascader="true"
                            value-filed="id"
                            placeholder="业务架构"
                            label-filed="groupName"
                            @change="querydata()"
                        />
                    </el-form-item>
                    <el-form-item label="">
                        <el-select
                            v-model="searchForm.distributorCategory"
                            clearable
                            placeholder="分销商分类"
                            @change="querydata()"
                        >
                            <el-option
                                v-for="item in distributorCategoryOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="">
                        <el-select
                            v-model="searchForm.distributorLevel"
                            clearable
                            placeholder="分销商等级"
                            @change="querydata()"
                        >
                            <el-option
                                v-for="item in distributorLevelOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="">
                        <el-input
                            v-model="searchForm.distributorNo"
                            clearable
                            placeholder="分销商编码"
                            @keyup.enter.native="querydata()"
                        ></el-input>
                    </el-form-item>
                </div>
                <div v-if="conceal" class="hidden">
                    <el-form-item label="">
                        <el-input
                            v-model="searchForm.ownerName"
                            clearable
                            placeholder="负责人"
                            @keyup.enter.native="querydata()"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <el-select
                            v-model="searchForm.enabled"
                            clearable
                            placeholder="状态"
                            @change="querydata()"
                        >
                            <el-option
                                v-for="item in statusOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
            <div class="right">
                <div class="search-btn">
                    <div class="more" @click="contentChange">
                        <span v-show="!conceal">展开</span>
                        <span v-show="conceal">收起</span>
                        <i v-show="!conceal" class="el-icon-arrow-down"></i>
                        <i v-show="conceal" class="el-icon-arrow-up"></i>
                    </div>
                    <div class="custom-btn">
                        <el-button
                            type="primary"
                            @click="querydata"
                        >
                            查询
                        </el-button>
                        <el-button @click="reset">重置</el-button>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <gb-table
                :table="table"
                :is-copy="true"
                :is-show-pagination="true"
                @pageChange="pageChange"
                @sizeChange="sizeChange"
            />
        </div>
    </div>
</template>

<script>
import department from '@/components/Department'
export default {
    name: 'DistributionList',
    components: { department },
    data () {
        return {
            table: {
                total: 0,
                currentPage: 1,
                pageSize: 20,
                data: [],
                // 标题
                column: [
                    {
                        prop: 'distributorNo',
                        label: '分销商编码',
                        minWidth: '100'
                    },
                    {
                        prop: 'distributorName',
                        label: '分销商名称',
                        minWidth: '100',
                        showTip: true
                    },
                    {
                        prop: 'partnerName',
                        label: '合作主体',
                        minWidth: '100',
                        showTip: true
                    },
                    { prop: 'ownerName', label: '负责人', minWidth: '100' },
                    { prop: 'groupName', label: '业务架构', minWidth: '100' },
                    // { prop: 'accountType', label: '结算方式', minWidth: '100' },
                    {
                        prop: 'distributorCategory',
                        label: '分销商分类',
                        minWidth: '100',
                        formatter: row => {
                            const types = this.distributorCategoryOptions.map(
                                item => item.label
                            )
                            return row.distributorLevel !== null
                                ? types[row.distributorCategory - 1]
                                : ''
                        }
                    },
                    {
                        prop: 'distributorLevel',
                        label: '分销商等级',
                        minWidth: '100',
                        formatter: row => {
                            const types = this.distributorLevelOptions.map(
                                item => item.label
                            )
                            return row.distributorLevel !== null
                                ? types[row.distributorLevel - 1]
                                : ''
                        }
                    },
                    // {
                    //   prop: 'hasContract',
                    //   label: '是否签合同',
                    //   minWidth: '100',
                    //   formatter: (row) => {
                    //     return row.hasContract ? '是' : '否'
                    //   },
                    // },
                    // {
                    //   prop: 'invoiceType',
                    //   label: '是否开票',
                    //   minWidth: '100',
                    //   formatter: (row) => {
                    //     const types = [
                    //       '不开发票',
                    //       '纸质普票',
                    //       '纸质专票',
                    //       '电子普票',
                    //       '电子专票',
                    //     ]
                    //     return row.invoiceType !== null ? types[row.invoiceType] : ''
                    //   },
                    // },
                    {
                        prop: 'enabled',
                        label: '状态',
                        minWidth: '100',
                        type: 'switch'
                    }
                ]
            },
            // 查询条件数据
            searchForm: {
                distributorName: null,
                partnerName: null,
                groupId: null,
                distributorCategory: null,
                distributorLevel: null,
                distributorNo: null,
                ownerName: null,
                enabled: null
            },
            conceal: false,
            statusOptions: [
                {
                    value: false,
                    label: '禁用'
                },
                {
                    value: true,
                    label: '启用'
                }
            ],
            distributorCategoryOptions: [
                {
                    value: 1,
                    label: '一二类电商分销店铺'
                },
                {
                    value: 2,
                    label: '平台'
                },
                {
                    value: 3,
                    label: '社群'
                },
                {
                    value: 4,
                    label: '微商'
                },
                {
                    value: 5,
                    label: '社群团购'
                },
                {
                    value: 6,
                    label: '礼品'
                },
                {
                    value: 7,
                    label: '赠品'
                },
                {
                    value: 8,
                    label: '线下商超'
                },
                {
                    value: 9,
                    label: '企业福利商城（员工平台）'
                },
                {
                    value: 10,
                    label: '企业团购'
                },
                {
                    value: 11,
                    label: 'O2O'
                },
                {
                    value: 12,
                    label: '企业积分商城（B2C平台）'
                },
                {
                    value: 13,
                    label: '经销商'
                }
            ],
            distributorLevelOptions: [
                {
                    value: 1,
                    label: 'SSS'
                },
                {
                    value: 2,
                    label: 'SS'
                },
                {
                    value: 3,
                    label: 'S'
                },
                {
                    value: 4,
                    label: '特级'
                },
                {
                    value: 5,
                    label: '高级'
                },
                {
                    value: 6,
                    label: '一级'
                }
            ],
            groups: []
        }
    },
    created () {
        this.initData()
        this.getOptionData()
        this.getOa()
        console.log(this.$store.getters.cachedViews)
    },
    methods: {
        getOa () {
            this.$axios.fetch('oaServer', '/oa/oa-group-list').then(res => {
                this.groups = res.data
            })
        },
        initData () {
            this.$axios
                .fetch(
                    'distributionServer',
                    '/distribution/distributor-info-page',
                    Object.assign(
                        {},
                        {
                            size: this.table.pageSize,
                            current: this.table.currentPage
                        },
                        this.searchForm
                    )
                )
                .then(res => {
                    console.log(res)
                    this.table.data = res.data
                    this.table.total = res.total
                })
        },
        // 从字典获取<分销商分类>
        getOptionData () {
            this.$axios
                .fetch('commonServer', '/dict/getItemViewsByParentNo', {
                    parentNos: '分销商渠道'
                })
                .then(res => {
                    // this.reasons = res.data.map(item => {
                    //   return item.itemValue;
                    // })
                    // this.leave = this.reasons[this.iscur];
                })
        },
        pageChange (val) {
            this.table.currentPage = val
            this.initData()
        },
        sizeChange (val) {
            this.table.currentPage = 1
            this.table.pageSize = val
            this.initData()
        },
        /* 查询第几页数据 */
        handleCurrentChange (val) {
            this.searchForm.pageIndex = val
            this.initData(this.searchForm)
        },
        handlesSizeChange (val) {
            this.currentPage = 1
            this.searchForm.pageIndex = 1
            this.searchForm.pageSize = val
            this.initData(this.searchForm)
        },
        querydata () {
            this.table.currentPage = 1
            this.initData()
        },
        contentChange () {
            this.conceal = !this.conceal
        },
        reset () {
            this.searchForm = {
                distributorName: null,
                partnerName: null,
                groupId: null,
                distributorCategory: null,
                distributorLevel: null,
                distributorNo: null,
                ownerName: null,
                enabled: null
            }
            this.$refs.dept.cascaderValue = ''
            this.table.currentPage = 1
            this.initData()
        }
    }
}
</script>
