<template>
  <div class='distrbution-add'>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="主体信息" name="subjectInfo">
        <subject-info />
      </el-tab-pane>
      <el-tab-pane label="基础信息" name="baseInfo">
        <base-info />
      </el-tab-pane>
      <el-tab-pane label="合同记录" name="third">
        <contract-records />
      </el-tab-pane>
      <el-tab-pane label="日志" name="fourth">
        <log />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import SubjectInfo from './subject-info';
import BaseInfo from './base-info';
import ContractRecords from './contract-records';
import Log from './log';
export default {
  name: 'DistrbutionAdd',
  components: { SubjectInfo, BaseInfo, ContractRecords, Log },
  data () {
    return {
      activeName: 'subjectInfo'
    }
  },
  created () { },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event);
    }
  },
}
</script>
<style lang='less' scoped>
.distrbution-add {
  background-color: #fff;
  height: 100%;
}
</style>