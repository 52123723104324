<template>
    <!-- 分销商日报 -->
    <div class="daily page">
        <!-- <gb-table :table="table" :isShowPagination="true" @pageChange="pageChange" @sizeChange="sizeChange" /> -->
        <el-form
            class="page-search"
            :inline="true"
            label-width="100px"
            @submit.native.prevent
        >
            <div class="left">
                <el-form-item label="">
                    <el-input
                        v-model="searchForm.distributorName"
                        placeholder="分销商名称"
                        clearable
                        @keyup.enter.native="querydata"
                        @clear="querydata"
                    ></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :clearable="false"
                        @change="dateChange"
                    >
                    </el-date-picker>
                </el-form-item>
            </div>
            <div class="right">
                <div class="search-btn">
                    <div class="custom-btn">
                        <el-button type="primary" @click="querydata">查询</el-button>
                        <el-button @click="reset">重置</el-button>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <gb-table
                v-show="show"
                ref="table"
                :table="table"
                :show-summary="true"
                :is-show-pagination="false"
                :summary-method="getSummaries"
                @pageChange="pageChange"
                @sizeChange="sizeChange"
            />
        </div>
    </div>
</template>

<script>
import { moneyFilter } from '@/utils/index'
export default {
    name: 'DistributionList',
    components: {},
    data () {
        return {
            table: {
                data: [],
                // firstColumn: { type: 'index' },
                // 标题
                column: [
                ]
            },
            // 查询条件数据
            searchForm: {
                groupIds: [],
                selectFrom: '',
                selectTo: '',
                distributorName: ''
            },
            dateRange: [],
            totalData: [],
            show: false
        }
    },
    created () {
        this.searchForm.selectFrom = this.$moment().subtract(7, 'day').format('YYYY-MM-DD')
        this.searchForm.selectTo = this.$moment().subtract(1, 'days').format('YYYY-MM-DD')
        this.dateRange = [
            new Date(this.searchForm.selectFrom), new Date(this.searchForm.selectTo)
        ]
        // this.table.column[1].label = `${new Date().getMonth() + 1}月合计`;
        this.initData()
        this.getOptionData()
    },
    methods: {
        initData (search) {
            let days = this.$moment(this.dateRange[1]).diff(this.$moment(this.dateRange[0]), 'days')
            if (days > 30) {
                this.$message.warning('最多查询30天数据！')
                return
            }
            this.show = false
            this.table.column = [
                { prop: 'distributorName', label: '分销商名称', minWidth: '200', showTip: true, align: 'left', tip: '销量/销售额', fixed: true },
                {
                    prop: 'total',
                    label: '合计',
                    'minWidth': '180',
                    formatter: (row) => {
                        let count = row.totalData.itemCountSum
                        let account = moneyFilter(row.totalData.itemAmountSum)
                        return `${count}/${'￥' + account}`
                    }
                }
            ]
            this.$axios.fetch('distributionServer', '/distribution/distributor-daily-report', this.searchForm, 'json').then(res => {
                this.totalData = this.table.data = res.data
                const { selectFrom, selectTo } = this.searchForm
                let count = this.$moment(selectTo).diff(this.$moment(selectFrom), 'day')
                // console.log(count);
                for (let index = 0; index <= count; index++) {
                    let obj = {
                        'minWidth': 150
                    }
                    obj.label = this.$moment(selectFrom).add(index, 'day').format('YYYY-MM-DD')
                    obj.formatter = (data) => {
                        let count = data.distributionDailyReports[index].itemCount
                        let account = moneyFilter(data.distributionDailyReports[index].itemAmount)
                        return `${count}/${'￥' + account}`
                    }
                    this.table.column.push(obj)
                }
                if (search) {
                    if (this.searchForm.distributorName && this.searchForm.distributorName.length > 0) {
                        this.table.data = this.totalData.filter(item => {
                            return item.distributorName.includes(this.searchForm.distributorName.replace(/\s+/g, ''))
                        })
                    } else {
                        this.table.data = this.totalData
                    }
                }
                this.show = true
            })
        },
        dateChange (val) {
            if (val && val.length > 0) {
                this.searchForm.selectFrom = val[0]
                this.searchForm.selectTo = val[1]
            }
        },
        // 从字典获取<分销商分类>
        getOptionData () {
            this.$axios
                .fetch('commonServer', '/dict/getItemViewsByParentNo', {
                    parentNos: '分销商渠道'
                })
                .then(res => {

                })
        },
        pageChange (val) {
            this.table.currentPage = val
            this.initData()
        },
        sizeChange (val) {
            this.table.currentPage = 1
            this.table.pageSize = val
            this.initData()
        },
        /* 查询第几页数据 */
        handleCurrentChange (val) {
            this.searchForm.pageIndex = val
            this.initData(this.searchForm)
        },
        handlesSizeChange (val) {
            this.currentPage = 1
            this.searchForm.pageIndex = 1
            this.searchForm.pageSize = val
            this.initData(this.searchForm)
        },
        querydata () {
            this.initData(true)
        },
        contentChange () {
            this.conceal = !this.conceal
        },
        reset () {
            let selectFrom = this.$moment().subtract(7, 'day').format('YYYY-MM-DD')
            let selectTo = this.$moment().subtract(1, 'days').format('YYYY-MM-DD')
            // console.log(selectFrom === this.searchForm.selectFrom);
            // console.log(selectTo === this.searchForm.selectTo);
            // if(selectFrom === this.searchForm.selectFrom && selectTo === this.searchForm.selectTo && this.searchForm.distributorName.length === 0){
            //   // 不需要重新请求
            // } else {
            this.searchForm.distributorName = []
            this.searchForm.selectFrom = selectFrom
            this.searchForm.selectTo = selectTo
            this.dateRange = [
                new Date(this.searchForm.selectFrom), new Date(this.searchForm.selectTo)
            ]
            this.initData(true)
            // }
        },
        // 合计
        getSummaries (param) {
            const { columns, data } = param
            const sums = []
            if (data) {
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = ''
                        return
                    }
                    if (index === 1) {
                        sums[index] = '合计'
                    } else if (index === 2) {
                        const valueToal = data.map(item => Number(item.totalData.itemAmountSum))
                        const salesTotal = data.map(item => Number(item.totalData.itemCountSum))
                        let total1 = valueToal.reduce((prev, curr) => {
                            const value = Number(curr)
                            if (!isNaN(value)) {
                                return prev + curr
                            } else {
                                return prev
                            }
                        }, 0)
                        let total2 = salesTotal.reduce((prev, curr) => {
                            const value = Number(curr)
                            if (!isNaN(value)) {
                                return prev + curr
                            } else {
                                return prev
                            }
                        }, 0)
                        sums[index] = `${total2}/￥${moneyFilter(total1)}`
                    } else {
                        const values = data.map(item => Number(item.distributionDailyReports[index - 3].itemAmount))
                        const sales = data.map(item => Number(item.distributionDailyReports[index - 3].itemCount))
                        if (!values.every(value => isNaN(value))) {
                            let total1 = values.reduce((prev, curr) => {
                                const value = Number(curr)
                                if (!isNaN(value)) {
                                    return prev + curr
                                } else {
                                    return prev
                                }
                            }, 0)
                            let total2 = sales.reduce((prev, curr) => {
                                const value = Number(curr)
                                if (!isNaN(value)) {
                                    return prev + curr
                                } else {
                                    return prev
                                }
                            }, 0)
                            sums[index] = `${total2}/￥${moneyFilter(total1)}`
                        } else {
                            sums[index] = 'N/A'
                        }
                    }
                })
            }
            return sums
        }
    }
}
</script>
<style lang='less' scoped>
// .daily {
//   /deep/ .el-table {
//     .el-table__header-wrapper {
//       overflow-x: auto!important;
//     }
//   }
// }
</style>
